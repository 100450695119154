import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 40px;
  z-index: 6;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  cursor: pointer;
`;

const LayerName = styled.span`
  color: ${(props) => (props.toggle ? props.theme.colors.milk : props.active ? props.theme.colors.lemon : 'white')};
  align-self: ${(props) => (props.toggle ? 'left' : 'center')};
  font-size: 12px;
  padding: 8px;
  transition: all 0.4s;

  ${LayerWrapper}:hover & {
    color: ${(props) => props.theme.colors.lemon};
  }
`;

const Layer = styled.button`
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid;
  cursor: pointer;
  border-color: ${(props) =>
    props.toggle ? props.theme.colors.black : props.active ? props.theme.colors.lemon : props.theme.colors.grey};
  border-radius: 8px;
  background: url(${(props) => props.url}) center no-repeat;
  background-size: cover;
  transition: all 0.4s;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.8);

  ${LayerWrapper}:hover & {
    border: 2px solid ${(props) => props.theme.colors.lemon};
  }

  :hover {
    border: 2px solid ${(props) => props.theme.colors.lemon};
  }
`;

const LayerToggle = styled(Layer)`
  background: url('./layers/Layers Switch.webp') center no-repeat;
  box-shadow: none;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
`;

const Chevron = styled(FontAwesomeIcon)`
  color: white;
  padding: 8px;
`;

export const LayersMenu = ({select, selected, options = []}) => {
  const [openLayerMenu, setOpenLayerMenu] = useState(false);
  const menuRef = useRef(null);

  const toggleLayer = () => setOpenLayerMenu(!openLayerMenu);

  const closeMenu = (e) => {
    if (menuRef.current && openLayerMenu && !menuRef.current.contains(e.target)) {
      setOpenLayerMenu(false);
    }
  };

  document.addEventListener('mousedown', closeMenu);

  return (
    <Container ref={menuRef}>
      {openLayerMenu && (
        <Menu>
          {options.map((item) => {
            return (
              <LayerWrapper key={item.id} onClick={() => select(item.name)}>
                <Layer url={item.url} active={item.name == selected} />
                <LayerName active={item.name == selected}>{item.title}</LayerName>
              </LayerWrapper>
            );
          })}
        </Menu>
      )}

      <LayerToggle toggle onClick={toggleLayer} url={'/layers/Layer Switch.svg'}>
        <Chevron icon={openLayerMenu ? faChevronDown : faChevronUp} />
        <LayerName toggle>Base maps</LayerName>
      </LayerToggle>
    </Container>
  );
};

LayersMenu.propTypes = {
  select: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  options: PropTypes.array,
};
