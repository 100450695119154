import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Button, InvertButton} from './common';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  background: #16171c;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 200px;
  padding: 24px 16px;
  gap: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > span {
    text-align: right;
  }
`;

const RowTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const Positive = styled.span`
  color: #2aba67;
`;

const Alarm = styled.span`
  color: #ff6b76;
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
`;

const EditButton = styled(Button)`
  border: none;

  > span {
    font-size: 14px;
    margin-bottom: 2px;
  }
`;

const DeleteButton = styled(Button)`
  border: none;
  color: #ff6b76;

  :hover {
    background: #ff6b76;
    color: black;
  }
`;

export const Alert = ({deleteAlert, period, index, title, toggleDrawMapState, manageAlert}) => {
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {manageAlert ? (
        <Row>
          <RowTitle>Threshold</RowTitle>
          {index > 0 ? <Positive>{index}%</Positive> : <Alarm>{index}%</Alarm>}
        </Row>
      ) : (
        <Row>
          <RowTitle>Vegetation Index</RowTitle>
          {index > 0 ? <Positive>{index}%</Positive> : <Alarm>{index}%</Alarm>}
        </Row>
      )}
      {!manageAlert && (
        <Row>
          <RowTitle>Period</RowTitle>
          <span>{period}</span>
        </Row>
      )}
      {manageAlert ? (
        <Row>
          <DeleteButton>
            <span>
              <FontAwesomeIcon icon={faTrash} />
            </span>
            Delete
          </DeleteButton>
          <EditButton>
            <span>
              <FontAwesomeIcon icon={faEdit} />
            </span>
            Edit
          </EditButton>
        </Row>
      ) : (
        <Row>
          <Button onClick={deleteAlert}>Ignore</Button>
          <InvertButton onClick={toggleDrawMapState}>Explore</InvertButton>
        </Row>
      )}
    </Wrapper>
  );
};

Alert.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  index: PropTypes.number,
  period: PropTypes.string,
  deleteAlert: PropTypes.func,
  toggleDrawMapState: PropTypes.func,
  manageAlert: PropTypes.bool,
};
