import React, {useState} from 'react';
import {Dashboard, Map, Sidebar} from '../../components';

import {MAP_STATE} from './constants';

const Home = () => {
  const [mapState, setMapState] = useState(MAP_STATE[0].state);
  const [dashboard, setDashboard] = useState(true);
  const [polygon, setPolygon] = useState(true);

  const toggleMapState = (mapState) => {
    setMapState(mapState);
  };

  const toggleDrawMapState = () => {
    setDashboard(false);
    setMapState(MAP_STATE[2].state);
    setPolygon(true);
  };

  const openMap = () => setDashboard(false);
  const openDashboard = () => setDashboard(true);

  return (
    <>
      {dashboard ? (
        <Dashboard openMap={openMap} toggleDrawMapState={toggleDrawMapState} />
      ) : (
        <Map mapState={mapState} toggleDrawMapState={toggleDrawMapState} polygon={polygon} setPolygon={setPolygon} />
      )}
      <Sidebar
        select={toggleMapState}
        selected={mapState}
        options={MAP_STATE}
        openDashboard={openDashboard}
        dashboard={dashboard}
      />
    </>
  );
};

export default Home;
