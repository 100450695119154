import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {HistoricOverView} from './historic-overview';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 40px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const HistoricInfo = styled.div`
  background: #16171c;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(25% - 16px);
  width: 240px;
  padding: 16px;
  gap: 16px;
  color: #b2b2b2;
`;

const Number = styled.span`
  font-size: 32px;
  font-weight: 700;
`;

const Danger = styled(Number)`
  color: white;
  font-size: 24px;
`;

const Negative = styled(Number)`
  color: #ff6b76;
`;

const Positive = styled(Number)`
  color: #2aba67;
`;

const InfoWrapper = styled(Column)`
  gap: 16px;
  width: 240px;
`;

export const Vegetation = ({area}) => {
  return (
    <Wrapper>
      <HistoricOverView />
      <InfoWrapper>
        <HistoricInfo>
          {area.vegetation < 0 ? <Negative>{area.vegetation}%</Negative> : <Positive>{area.vegetation}%</Positive>}
          Average Vegetation Index
        </HistoricInfo>
        <HistoricInfo>
          {area.increase < 0 ? <Negative>{area.increase}%</Negative> : <Positive>{area.increase}%</Positive>}
          Average Vegetation Increase
        </HistoricInfo>
        <HistoricInfo>
          {area.decrease < 0 ? <Negative>{area.decrease}%</Negative> : <Positive>{area.decrease}%</Positive>}
          Average Vegetation Decrease
        </HistoricInfo>
        <HistoricInfo>
          <Danger>{area.danger}</Danger>
          Deforestation danger
        </HistoricInfo>
      </InfoWrapper>
    </Wrapper>
  );
};

Vegetation.propTypes = {
  area: PropTypes.object,
};
