import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  height: 48px;
  position: absolute;
  background: #16171c;
  display: flex;
  align-items: center;
  width: 328px;
  border-radius: 8px;
  margin: 40px;
  cursor: pointer;
  z-index: ${(props) => (props.geoCoder ? '3' : '-1')};
`;

const SearchIcon = styled.div`
  display: flex;
  position: absolute;
  right: -2px;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.lemon};
  width: 48px;
  height: 48px;
  border-radius: 8px;
  color: black;
  font-size: 18px;
`;

const propTypes = {
  geocoderContainerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  ]),
  geoCoder: PropTypes.bool.isRequired,
};

export const SearchBox = ({geocoderContainerRef, geoCoder}) => {
  return (
    <Wrapper ref={geocoderContainerRef} geoCoder={geoCoder}>
      <SearchIcon>
        <FontAwesomeIcon icon={faSearch} />
      </SearchIcon>
    </Wrapper>
  );
};

SearchBox.propTypes = propTypes;
