import React, {useRef} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 8px;
  width: 160px;
  background: #16171c;
  position: absolute;
  top: 82px;
  right: 12px;
`;

const ManageAction = styled.div`
  color: white;
  padding: 14px 12px 14px 16px;
  display: flex;
  felx-direction: row;
  gap: 16px;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;

  :hover {
    transform: scale(1.02);
  }
`;

export const CommentsActions = ({manageComment, setManageComment}) => {
  const dropdownRef = useRef(null);

  const closeDropdown = (e) => {
    if (dropdownRef.current && manageComment && !dropdownRef.current.contains(e.target)) {
      setManageComment(false);
    }
  };

  document.addEventListener('mousedown', closeDropdown);

  return (
    <Wrapper ref={dropdownRef}>
      <ManageAction>All</ManageAction>
      <ManageAction>
        <FontAwesomeIcon icon={faCheck} />
        Only open
      </ManageAction>
      <ManageAction>Only resolved</ManageAction>
      <ManageAction>Only my threads</ManageAction>
    </Wrapper>
  );
};

CommentsActions.propTypes = {
  manageComment: PropTypes.bool,
  setManageComment: PropTypes.func,
};
