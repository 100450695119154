import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {AreaAlertsPanel} from './area-alerts-panel';
import {AreasOverview} from './areas-overview';
import {Statistics} from '../statistics/statistics-overview';

const Container = styled.div`
  width: calc(100vw - 60px);
  margin-left: 60px;
  height: 100vh;
  overflow: auto;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.black};
  padding: 40px;
  gap: 40px;
`;

export const Dashboard = ({openMap, toggleDrawMapState}) => {
  const [area, setArea] = useState(null);
  const [openStatistics, setOpenStatistics] = useState(false);
  const [openAlerts, setOpenAlerts] = useState(false);

  const closeAlertsPanel = () => setOpenAlerts(false);

  const closeStatistics = () => setOpenStatistics(false);

  const openAreaAlerts = (id, array) => () => {
    setOpenAlerts(true);
    setArea(array[id]);
  };

  const openStatisticsOverview = (id, array) => () => {
    setOpenStatistics(true);
    setArea(array[id]);
  };

  return (
    <Container>
      {openStatistics ? (
        <Statistics area={area} closeStatistics={closeStatistics} toggleDrawMapState={toggleDrawMapState} />
      ) : (
        <AreasOverview
          openMap={openMap}
          openAreaAlerts={openAreaAlerts}
          openStatisticsOverview={openStatisticsOverview}
        />
      )}
      {openAlerts && (
        <AreaAlertsPanel
          alerts={area?.alerts}
          area={area}
          closeAlertsPanel={closeAlertsPanel}
          toggleDrawMapState={toggleDrawMapState}
        />
      )}
    </Container>
  );
};

Dashboard.propTypes = {
  openMap: PropTypes.func,
  toggleDrawMapState: PropTypes.func,
};
