import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Header} from './common';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLink, faLocationArrow} from '@fortawesome/free-solid-svg-icons';
import {MEMBERS_LIST} from './constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 24px 24px 24px;
  gap: 24px;
`;

const EmailRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const EmailInput = styled.input`
  width: 340px;
  border-radius: 8px;
  font-size: 16px;
  padding: 14px 16px;
  background: #16171c;
  color: white;
`;

const SendButton = styled.button`
  background: ${(props) => props.theme.colors.lemon};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  border-radius: 8px;
  transition: 0.4s all;
  cursor: pointer;

  > span {
    transform: rotate(40deg),
    margin-left: -2px
  }

  :hover {
    background: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.lemon};
    border: 1px solid #ffec26;
  }
`;

const CopyLink = styled.button`
  display: flex;
  gap: 12px;
  padding: 14px 16px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: 0.4s all;
  border-radius: 8px;
  margin-bottom: 30px;

  :hover {
    color: ${(props) => props.theme.colors.lemon};
    transform: scale(1.02);
  }
`;

export const MemberRow = styled(EmailRow)`
  gap: 16px;
  align-items: center;
  color: white;
  cursor: pointer;
  transition: 0.4s all;

  :hover {
    transform: scale(1.01);
  }
`;

const Checkbox = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 2px solid white;
`;

export const Avatar = styled.div`
  background-color: ${(props) => props.color};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Member = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > span {
    font-size: 16px;
  }

  > label {
    color: ${(props) => props.theme.colors.grey};
    font-size: 12px;
    cursor: pointer;
  }
`;

export const ShareView = () => {
  const [message, setMessage] = useState('');
  return (
    <Container>
      <Header>Share map and analytics</Header>
      <EmailRow>
        <EmailInput
          placeholder="Enter email address"
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <SendButton>
          <FontAwesomeIcon style={{transform: 'rotate(40deg)', marginLeft: '-2px'}} icon={faLocationArrow} />
        </SendButton>
      </EmailRow>
      <CopyLink>
        <FontAwesomeIcon icon={faLink} />
        Copy link to selected area
      </CopyLink>
      <Header>Workspace members:</Header>
      {MEMBERS_LIST.map((item) => {
        return (
          <MemberRow key={item.id}>
            <Checkbox />
            <Avatar color={item.color}>{item.initials}</Avatar>
            <Member>
              <span>{item.name}</span>
              <label>{item.eMail}</label>
            </Member>
          </MemberRow>
        );
      })}
    </Container>
  );
};

ShareView.propTypes = {};
