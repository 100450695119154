import styled from 'styled-components';

export const Header = styled.h4`
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  letter-spacing: 0.02em;
  color: white;
`;

export const ToolBar = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
`;

export const ToolButton = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.4s all;
  width: 48px;
  height: 48px;

  :hover {
    transform: scale(1.2);
    background: #16171c;
  }
`;
