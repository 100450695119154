import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  border-radius: 8px;
  width: 160px;
  background: #16171c;
  position: absolute;
  top: 68px;
  right: 16px;
  z-index: 6;
`;

const Action = styled.div`
  color: white;
  padding: 14px 12px 14px 16px;
  display: flex;
  cursor: pointer;
  transition: 0.3s all;
  > span {
    color: #ff6b76;
  }

  :hover {
    transform: scale(1.02);
  }
`;

export const CommentAction = ({openActions, setOpenActions}) => {
  const dropdownRef = useRef(null);

  const closeDropdown = (e) => {
    if (dropdownRef.current && openActions && !dropdownRef.current.contains(e.target)) {
      setOpenActions(false);
    }
  };

  document.addEventListener('mousedown', closeDropdown);

  return (
    <Wrapper ref={dropdownRef}>
      <Action>Mark resolved</Action>
      <Action>Copy link to area</Action>
      <Action>
        <span>Delete</span>
      </Action>
    </Wrapper>
  );
};

CommentAction.propTypes = {
  openActions: PropTypes.bool,
  setOpenActions: PropTypes.func,
};
