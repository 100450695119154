import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.black};
  color: ${(props) => (props.active ? 'white' : '#b2b2b2')};
  font-size: ${(props) => (props.active ? '14px' : '10px')};
  display: ${(props) => (props.invisible ? 'none' : 'flex')};
  align-items: center;
  justify-content: ${(props) => (props.active ? 'flex-start' : 'center')};
  height: 66px;
  width: 72px !important;
  box-sizing: border-box;
  flex-direction: column;
  gap: 8px;
  position: relative;
  transition: 0.4s all;
  cursor: grab;

  > label {
    background: ${(props) => (props.active ? 'yellow' : '#b2b2b2')};
    width: 2px;
    height: ${(props) => (props.active ? '40px' : '16px')};
  }

  > span {
    position: absolute;
    bottom: 0;
    margin: ;
  }
`;

export const Month = ({title, active, invisible}) => {
  return (
    <Wrapper active={active} invisible={invisible}>
      <label />
      <span>{title}</span>
    </Wrapper>
  );
};

Month.propTypes = {
  title: PropTypes.string,
  active: PropTypes.bool,
  invisible: PropTypes.bool,
};
