import React from 'react';
import PropTypes from 'prop-types';
import {ThemeProvider} from 'styled-components';

const propTypes = {
  children: PropTypes.array.isRequired,
};

const theme = {
  colors: {
    white: '#ffffff',
    black: '#0c0d12',
    milk: '#e2e2e2',
    lemon: '#ffec26',
    grey: '#9a9a9a',
  },
};

export const Theme = ({children}) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

Theme.propTypes = propTypes;
