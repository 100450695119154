import React, {useState, useRef, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {StaticMap} from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';
import {MapView} from '@deck.gl/core';
import DeckGL from '@deck.gl/react';
import {DARK_MAP, INITIAL_VIEW_STATE, MAPS, LIGHT_MAP, MAPBOX_TOKEN, SATELLITE, PROMT_TEXT} from './constants';
import {MapToolsBar} from './map-tools';
import 'mapbox-gl/dist/mapbox-gl.css';
import {LayersMenu} from './layers-menu';
import {InfoToolsBar} from './info-tools-bar';
import {Legend} from './legend';
import {SearchBox} from './search-box';
import {DrawPointMode, DrawPolygonMode} from 'nebula.gl';
import {renderHeatmapLayer} from './layers/heatmap-layer';
import {renderIconLayer} from './layers/icon-layer';
import {renderEditableLayer} from './layers/editable-layer';
import {SidePanel} from '../side-panel/side-panel';
import {renderMessagesPinLayer} from './layers/messages-icon-layer';
import DateSlider from '../date-slider/slider';

const MapWrapper = styled.div`
  width: calc(100vw - 60px);
  margin-left: 60px;
  height: 100vh;
  position: relative;
  background: black;
  display: flex;
`;

const VIEW = new MapView({
  id: 'main',
  controller: {
    inertia: true,
    scrollZoom: {smooth: true},
  },
  farZMultiplier: 4,
});

const Polygon = styled.div`
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  width: calc(100% - 100px);
  height: 100%;
  top: 0;
  left: 0;

  > img {
    width: 60%;
    height: auto;
  }
`;

const Promt = styled.div`
  position: absolute;
  bottom: 53px;
  width: 332px;
  left: calc(50% - 166px);
  border-radius: 16px;
  padding: 12px 16px;
  color: white;
  font-size: 14px;
  background: ${(props) => props.theme.colors.black};
  z-index: 22;
`;

const Map = ({mapState, toggleDrawMapState, polygon, setPolygon}) => {
  const [openLegend, setOpenLegend] = useState(true);
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [location, setLocation] = useState([[]]);
  const [promt, setPromt] = useState(true);
  const [map, setMap] = useState(SATELLITE);
  const [pin, setPin] = useState('darkPin.png');
  const [geoCoder, setGeoCoder] = useState(true);
  const [mode, setMode] = useState(() => DrawPolygonMode);
  const [features, setFeatures] = useState({
    type: 'FeatureCollection',
    features: [],
  });
  const [selectedFeatureIndexes] = useState([]);
  const mapRef = useRef();
  const geocoderContainerRef = useRef();

  const toggleLegend = () => {
    setOpenLegend(!openLegend);
  };

  const toggleSidePanel = () => {
    setOpenSidePanel(!openSidePanel);
  };

  useEffect(() => {
    mapState == 'Search' ? setOpenLegend(true) : setOpenLegend(false);
    mapState == 'Search' ? setGeoCoder(true) : setGeoCoder(false);
    mapState == 'Search' || mapState == 'Info' ? setOpenSidePanel(false) : setOpenSidePanel(true);
    mapState == 'Info' && features?.features.length === 0 ? setPromt(true) : setPromt(false);
    mapState == 'Info' && features?.features.length > 0 && setOpenSidePanel(true);
  }, [mapState, features?.features.length]);

  useEffect(() => {
    mapState == 'Draw' &&
      setFeatures((prevState) => ({
        ...prevState,
        features: [],
      }));
  }, [mapState]);

  useEffect(() => {
    mapState == 'Info' ? setMode(() => DrawPointMode) : setMode(() => DrawPolygonMode);
    map == LIGHT_MAP ? setPin('darkPin.png') : setPin('lightPin.png');

    mapState == 'Search' && state.longitude !== INITIAL_VIEW_STATE.longitude
      ? setLocation([state.longitude, state.latitude])
      : setLocation(features?.features[0]?.geometry?.coordinates);
  }, [mapState, map, state.longitude, state.latitude, features, features?.features, features?.length]);

  const renderLayers = (mapState, location) => {
    switch (mapState) {
      case 'Search':
        return [renderHeatmapLayer(), renderIconLayer(location, pin)];
      case 'Info':
        return [
          renderHeatmapLayer(),
          renderEditableLayer(features, setFeatures, mode, selectedFeatureIndexes),
          renderIconLayer(location, pin),
        ];
      case 'Draw':
        return [renderHeatmapLayer(), renderEditableLayer(features, setFeatures, mode, selectedFeatureIndexes)];
      case 'Alert':
        return [renderHeatmapLayer()];
      case 'Message':
        return [renderHeatmapLayer(), renderMessagesPinLayer()];
      default:
        return renderHeatmapLayer();
    }
  };

  const renderMaps = () => {
    switch (map) {
      case DARK_MAP:
        return MAPS[1].map;
      case SATELLITE:
        return MAPS[2].map;
      case LIGHT_MAP:
        return MAPS[0].map;
      default:
        return MAPS[2].map;
    }
  };

  const toggleMap = (map) => {
    setMap(map);
  };

  const zoomIn = () => {
    setState({
      ...state,
      zoom: 14,
    });
  };

  const zoomOut = () => {
    setState({
      ...state,
      zoom: 12,
    });
  };

  const changeLocation = () => {
    setState({
      ...INITIAL_VIEW_STATE,
      longitude: state.longitude,
      latitude: state.latitude,
      zoom: 11.5,
    });
  };

  const handleViewportChange = useCallback((newViewport) => setState(newViewport), []);

  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = {transitionDuration: 3000};

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    [handleViewportChange]
  );

  return (
    <MapWrapper>
      <DeckGL
        initialViewState={state}
        views={VIEW}
        controller={{doubleClickZoom: false}}
        layers={renderLayers(mapState, location)}
      >
        <StaticMap mapboxApiAccessToken={MAPBOX_TOKEN} mapStyle={renderMaps()} ref={mapRef} />
        <Geocoder
          mapRef={mapRef}
          containerRef={geocoderContainerRef}
          onViewportChange={handleGeocoderViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          placeholder="Enter search address"
          marker={false}
          zoom={12.9}
          minLength={2}
        />
      </DeckGL>
      <LayersMenu select={toggleMap} selected={map} options={MAPS} />
      <SearchBox geocoderContainerRef={geocoderContainerRef} geoCoder={geoCoder} />
      <InfoToolsBar
        toggleLegend={toggleLegend}
        toggleSidePanel={toggleSidePanel}
        mapState={mapState}
        openSidePanel={openSidePanel}
      />
      {mapState == 'Draw' && polygon && features.features.length === 0 && (
        <Polygon onClick={() => setPolygon(false)}>
          <img src={'./polygon.png'} alt="polygon" />
        </Polygon>
      )}
      {openLegend && <Legend toggleLegend={toggleLegend} mapState={mapState} />}
      {mapState !== 'Search' && openSidePanel && (
        <SidePanel openSidePanel={openSidePanel} mapState={mapState} toggleDrawMapState={toggleDrawMapState} />
      )}
      <MapToolsBar
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        changeLocation={changeLocation}
        state={state}
        location={location}
      />
      {promt && <Promt>{PROMT_TEXT}</Promt>}
      {!promt && <DateSlider openSidePanel={openSidePanel} />}
    </MapWrapper>
  );
};

Map.propTypes = {
  mapState: PropTypes.string,
  toggleDrawMapState: PropTypes.func,
  polygon: PropTypes.bool,
  setPolygon: PropTypes.func,
};

export default Map;
