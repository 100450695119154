import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const NavBar = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 60px;
  height: 100vh;
  background-color: #16171c;

  > img {
    margin: 6px;
    cursor: pointer;
  }
`;

const Login = styled.div`
  border-radius: 50%;
  width: 52px;
  height: 52px;
  background: #d4d2dc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-feature-settings: 'ss02' on;
  cursor: pointer;
`;

const NavContainer = styled.div`
  height: calc(100vh - 118px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  width: 48px;
  height: 48px;
  color: ${(props) => (props.active ? props.theme.colors.black : props.theme.colors.milk)};
  background: ${(props) => (props.active ? props.theme.colors.lemon : 'transparent')};
  font-size: 18px;
  transition: 0.5s all;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  :hover {
    background: ${(props) => props.theme.colors.lemon};
    color: ${(props) => props.theme.colors.black};
  }
`;

const Alarm = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.lemon};
  position: absolute;
  top: 7px;
  right: 10px;
`;

const Sidebar = ({select, selected, options, openDashboard, dashboard}) => {
  return (
    <NavBar>
      <img src="/logo.svg" alt="AE-logo" onClick={openDashboard} />
      <NavContainer>
        {!dashboard &&
          options.map((item) => {
            return (
              <Button key={item.id} onClick={() => select(item.state)} active={item.state == selected}>
                <FontAwesomeIcon icon={item.icon} />
                {item.active && <Alarm />}
              </Button>
            );
          })}
      </NavContainer>
      <Login>UN</Login>
    </NavBar>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  select: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  options: PropTypes.array,
  openDashboard: PropTypes.func,
  dashboard: PropTypes.bool,
};
