import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faMap} from '@fortawesome/free-solid-svg-icons';
import {AREAS} from './constants';
import {AreaCard} from './area-card';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  color: white;
  font-size: 48px;
  font-weight: 700;
  > span {
    color: ${(props) => props.theme.colors.lemon};
  }
`;

const MapButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.colors.lemon};
  cursor: pointer;
  gap: 11px;
  font-size: 16px;
  padding: 0 32px;
  border-radius: 8px;
  transition: 0.4s all;
  height: 50px;

  :hover {
    color: ${(props) => props.theme.colors.black};
    background: ${(props) => props.theme.colors.lemon};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100vw - 144px);
  gap: 40px;
`;

const ContentWithAlerts = styled(Content)`
  border-bottom: 1px solid #414141;
  padding-bottom: 40px;
`;

const NewAlertCard = styled.div`
  display: flex;
  flex-basis: calc(50% - 20px);
  height: 280px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23656565FF' stroke-width='1' stroke-dasharray='25%2c 9' stroke-dashoffset='20' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  transition: 0.4s all;

  :hover {
    border: 1px solid ${(props) => props.theme.colors.lemon};
  }
`;

const AddNewAlertButton = styled.button`
  border: 1px solid ${(props) => props.theme.colors.lemon};
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.lemon};
  width: 200px;
  padding: 20px 40px 20px 25px;
  font-size: 16px;
  transition: 0.4s all;
  gap: 12px;

  > span {
    font-size: 24px;
  }

  :hover {
    background: ${(props) => props.theme.colors.lemon};
    color: ${(props) => props.theme.colors.black};
  }
`;

export const AreasOverview = ({openMap, openAreaAlerts, openStatisticsOverview}) => {
  return (
    <>
      <Header>
        <Title>
          You are subscribed to <span>{AREAS.length} areas</span>
        </Title>
        <MapButton onClick={openMap}>
          <FontAwesomeIcon icon={faMap} />
          Open Map
        </MapButton>
      </Header>
      <ContentWithAlerts>
        {AREAS.slice(0, 2).map((item) => {
          return (
            <AreaCard
              key={item.id}
              title={item.title}
              url={item.url}
              alerts={item.alerts.length}
              vegetation={item.vegetation}
              onClick={openAreaAlerts(item.id, AREAS)}
              openStatisticsOverview={openStatisticsOverview(item.id, AREAS)}
            />
          );
        })}
      </ContentWithAlerts>
      <Content>
        {AREAS.slice(2, 5).map((item) => {
          return (
            <AreaCard
              key={item.id}
              title={item.title}
              url={item.url}
              alerts={item.alerts}
              vegetation={item.vegetation}
              openStatisticsOverview={openStatisticsOverview(item.id, AREAS)}
            />
          );
        })}
        <NewAlertCard>
          <AddNewAlertButton>
            <FontAwesomeIcon icon={faPlus} />
            <span>Add new</span>
          </AddNewAlertButton>
        </NewAlertCard>
      </Content>
    </>
  );
};

AreasOverview.propTypes = {
  openMap: PropTypes.func,
  toggleArea: PropTypes.func,
  openStatisticsOverview: PropTypes.func,
  openAreaAlerts: PropTypes.func,
};
