import {EditableGeoJsonLayer} from 'nebula.gl';

export const renderEditableLayer = (features, setFeatures, mode, selectedFeatureIndexes) => {
  return new EditableGeoJsonLayer({
    id: 'editable-geojson-layer',
    data: features,
    getEditHandlePointColor: () => [254, 236, 52],
    mode,
    selectedFeatureIndexes,
    _subLayerProps: {
      guides: {
        getFillColor: () => [254, 236, 52, 30],
        getLineColor: () => [254, 236, 52],
      },
      geojson: {
        getLineColor: () => [254, 236, 52],
        filled: false,
      },
    },
    onEdit: ({updatedData}) => {
      setFeatures((prevState) => ({
        ...prevState,
        features: updatedData?.features.slice(-1),
      }));
    },
  });
};
