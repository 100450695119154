import {IconLayer} from 'deck.gl';

export const renderIconLayer = (location, pin) => {
  return new IconLayer({
    id: 'Pin',
    data: location,
    billboard: true,
    getIcon: (d) => 'pin',
    getPosition: (d) => location,
    getSize: () => 7,
    iconAtlas: pin ? pin : 'darkPin.png',
    iconMapping: {
      pin: {
        x: 0,
        y: 0,
        width: 161,
        height: 225,
        anchorY: 225,
        mask: false,
      },
    },
    sizeScale: 7,
    alphaCutoff: pin == 'darkPin.png' ? 0.9 : 0.5,
    updateTriggers: {
      getPosition: [location],
    },
  });
};
