import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';

const PrevArrowWrapper = styled.div`
  position: absolute;
  bottom: 25px;
  left: 16px;
  font-size: 0;
  line-height: 0;
  z-index: 1;
`;

const NextArrowWrapper = styled(PrevArrowWrapper)`
  left: unset;
  right: 16px;
`;

const Button = styled.button`
  background: ${(props) => props.theme.colors.black};
  width: 48px;
  height: 48px;
  cursor: pointer;
  color: white;
  font-size: 18px;
`;

const getBackgroundColor = (withOpacity) => `rgba(244, 225, 27, ${withOpacity ? '0.4' : '1'})`;

export const PrevButton = (props) => {
  const {className, onClick} = props;

  return (
    <PrevArrowWrapper>
      <Button onClick={onClick} backgroundColor={getBackgroundColor(className?.indexOf('slick-disabled') !== -1)}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </Button>
      ;
    </PrevArrowWrapper>
  );
};

PrevButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export const NextButton = (props) => {
  const {className, onClick} = props;

  return (
    <NextArrowWrapper>
      <Button onClick={onClick} backgroundColor={getBackgroundColor(className?.indexOf('slick-disabled') !== -1)}>
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </NextArrowWrapper>
  );
};

NextButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
