import {HeatmapLayer} from 'deck.gl';
import {COLOR_RANGE, MAIN_DATA} from '../constants';

export const renderHeatmapLayer = () => {
  return new HeatmapLayer({
    id: 'Heatmap',
    data: MAIN_DATA,
    colorRange: COLOR_RANGE,
    debounceTimeout: 100,
    getPosition: (d) => [d.COORDINATES[0] + 71.853, d.COORDINATES[1] - 37.196416],
    getWeight: 1,
    intensity: 1,
    radiusPixels: 350,
    threshold: 0.05,
    opacity: 0.7,
  });
};
