import {faBell, faCircleInfo, faDrawPolygon, faMessage, faSearch} from '@fortawesome/free-solid-svg-icons';

export const MAP_STATE = [
  {
    id: 0,
    state: 'Search',
    icon: faSearch,
  },
  {
    id: 1,
    state: 'Info',
    icon: faCircleInfo,
  },
  {
    id: 2,
    state: 'Draw',
    icon: faDrawPolygon,
  },
  {
    id: 3,
    state: 'Alert',
    icon: faBell,
    active: true,
  },
  {
    id: 4,
    state: 'Message',
    icon: faMessage,
  },
];
