import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {Row, SectionTitle} from './common';

const Chartrow = styled(Row)`
  gap: 40px;
  padding: ${(props) => (props.sideBar ? '24px' : '20px 40px')};
  flex-wrap: wrap;
`;

const Chart = styled.div`
  flex-basis: ${(props) => (props.sideBar ? '100%' : 'calc(50% - 20px)')};
  > img {
    width: 100%;
    height: auto;
  }
`;

const ChartSpan = styled.span`
  font-size: 12px;
  color: #cfcfcf;
  padding: 30px 0 24px 0;
`;

const BlueChartSpan = styled(ChartSpan)`
  color: #26d8ff;
`;

export const Charts = ({sideBar}) => {
  return (
    <>
      <Chartrow sideBar={sideBar}>
        <Chart sideBar={sideBar}>
          <SectionTitle>Vegetation Distribution by Area</SectionTitle>
          <Row>
            <ChartSpan>Sq. Meters</ChartSpan>
          </Row>
          <img src={'./charts/chart1.png'} alt="chart" />
        </Chart>
        <Chart sideBar={sideBar}>
          <SectionTitle>Related to avegare air temperature</SectionTitle>
          <Row>
            <ChartSpan>%</ChartSpan>
            <BlueChartSpan>°F</BlueChartSpan>
          </Row>
          <img src={'./charts/chart2.png'} alt="chart" />
        </Chart>
        <Chart sideBar={sideBar}>
          <SectionTitle>Related to precipitation</SectionTitle>
          <Row>
            <ChartSpan>%</ChartSpan>
            <BlueChartSpan>inc.</BlueChartSpan>
          </Row>
          <img src={'./charts/chart3.png'} alt="chart" />
        </Chart>
        <Chart sideBar={sideBar}>
          <SectionTitle>Related to Illegal logging</SectionTitle>
          <Row>
            <ChartSpan>%</ChartSpan>
            <BlueChartSpan>thousand tons</BlueChartSpan>
          </Row>
          <img src={'./charts/chart4.png'} alt="chart" />
        </Chart>
      </Chartrow>
    </>
  );
};

Charts.propTypes = {
  sideBar: PropTypes.bool,
};
