import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Charts} from './charts';
import {Header} from './header';
import {SectionTitle} from './common';
import {Vegetation} from './vegetation';
import {HistoricOverView} from './historic-overview';

const Container = styled.div`
  width: 100%;
  height: ${(props) => (props.sideBar ? 'calc(100vh - 67px)' : '100vh')};
  position: absolute;
  top: ${(props) => (props.sideBar ? '67px' : '0')};
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-y: scroll;
  padding-bottom: ${(props) => (props.sideBar ? '60px' : 'unset')};
`;

const AlertsChart = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  > img {
    padding: 40px 0;
  }
`;

export const Statistics = ({area, closeStatistics, sideBar, toggleDrawMapState}) => {
  return (
    <Container sideBar={sideBar}>
      {!sideBar && (
        <>
          <Header area={area} closeStatistics={closeStatistics} toggleDrawMapState={toggleDrawMapState} />
          <Vegetation area={area} />
        </>
      )}
      {sideBar && <HistoricOverView sideBar={sideBar} />}
      <Charts sideBar={sideBar} />
      <AlertsChart>
        <SectionTitle>Vegetation Alerts</SectionTitle>
        <img src={sideBar ? './charts/Timeline - sidebar.png' : './charts/Timeline.png'} alt="chart" />
      </AlertsChart>
    </Container>
  );
};

Statistics.propTypes = {
  closeStatistics: PropTypes.func,
  area: PropTypes.object,
  sideBar: PropTypes.bool,
  toggleDrawMapState: PropTypes.func,
};
