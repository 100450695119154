export const MONTHS_LIST = [
  {
    id: 0,
    month: 'Jan, 2022',
  },
  {
    id: 1,
    month: 'Feb, 2022',
  },
  {
    id: 2,
    month: 'Mar, 2022',
  },
  {
    id: 3,
    month: 'Apr, 2022',
  },
  {
    id: 4,
    month: 'May, 2022',
  },
  {
    id: 5,
    month: 'Jun, 2022',
  },
  {
    id: 6,
    month: 'Jul, 2022',
  },
  {
    id: 7,
    month: 'Aug, 2022',
  },
  {
    id: 8,
    month: 'Sep, 2022',
  },
];
