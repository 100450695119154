import React from 'react';
import {Route, Routes, Navigate, BrowserRouter} from 'react-router-dom';

import * as Pages from './pages';
import {GlobalStyles} from './styles/global-styles';
import {Theme} from './styles/theme';

function App() {
  return (
    <BrowserRouter>
      <Theme>
        <GlobalStyles />
        <Routes>
          <Route path={'/'} element={<Navigate to="home" replace={true} />} />
          <Route path={'home'} index element={<Pages.Home />} />
        </Routes>
      </Theme>
    </BrowserRouter>
  );
}

export default App;
