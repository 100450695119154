import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/free-solid-svg-icons';
import {Alert} from '../alert';

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 440px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;
  color: white;
  gap: 24px;
  overflow: auto;
  overflow-y: scroll;
  background: ${(props) => props.theme.colors.black};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #5d5c6c;
  gap: 20px;
  padding-left: 24px;

  > button {
    cursor: pointer;
    color: white;
    font-size: 18px;
    transition: 0.4s all;

    :hover {
      color: ${(props) => props.theme.colors.lemon};
    }
  }
`;

const Title = styled.h4`
  color: white;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.02em;

  > span {
    color: ${(props) => props.theme.colors.lemon};
  }
`;

const Shadow = styled.div`
  position: fixed;
  right: 430px;
  top: 0;
  background: rgba(12, 13, 18, 0.5);
  width: calc(100% - 60px);
  height: 100vh;
`;

export const AreaAlertsPanel = ({closeAlertsPanel, alerts, area, toggleDrawMapState}) => {
  const [newAlerts, setNewAlerts] = useState(alerts);

  const deleteAlert = (id) => () => {
    setNewAlerts((alerts) => alerts.filter((item) => item.id !== id));
  };

  return (
    <>
      <Shadow />
      <Container>
        <Header>
          <button onClick={closeAlertsPanel}>
            <FontAwesomeIcon icon={faClose} />
          </button>
          <Title>Alerts: {area?.title}</Title>
        </Header>
        {newAlerts.map((item) => {
          return (
            <Alert
              key={item.id}
              index={item.index}
              period={item.period}
              deleteAlert={deleteAlert(item.id)}
              toggleDrawMapState={toggleDrawMapState}
            />
          );
        })}
      </Container>
    </>
  );
};

AreaAlertsPanel.propTypes = {
  alerts: PropTypes.array,
  closeAlertsPanel: PropTypes.func,
  title: PropTypes.string,
  area: PropTypes.object,
  toggleDrawMapState: PropTypes.func,
};
