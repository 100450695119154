import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faMinus, faLocationCrosshairs, faTrash} from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 40px;
  gap: 8px;
`;

const Button = styled.button`
  background: ${(props) => props.theme.colors.black};
  width: 48px;
  height: 48px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 21px;
  cursor: pointer;
  border-radius: 8px;

  :hover {
    background: ${(props) => props.theme.colors.lemon};
    color: ${(props) => props.theme.colors.black};
    transition: all 0.4s;
  }
`;

const ScaleBox = styled.div`
  border-radius: 8px;
  background: ${(props) => props.theme.colors.black};
`;

export const MapToolsBar = ({zoomIn, zoomOut, changeLocation}) => {
  return (
    <Container>
      <Button onClick={changeLocation}>
        <FontAwesomeIcon icon={faLocationCrosshairs} />
      </Button>
      <ScaleBox>
        <Button onClick={zoomIn}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <Button onClick={zoomOut}>
          <FontAwesomeIcon icon={faMinus} />
        </Button>
      </ScaleBox>
    </Container>
  );
};

MapToolsBar.propTypes = {
  zoomIn: PropTypes.func.isRequired,
  zoomOut: PropTypes.func.isRequired,
  changeLocation: PropTypes.func.isRequired,
};
