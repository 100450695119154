import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {InfoContent} from '../side-panel/info-content';
import {AlertsContent} from '../side-panel/alerts-content';
import {DrawContent} from './draw-content';
import {CommentsContent} from './comments-content';

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: ${(props) => props.theme.colors.black};
  height: 100vh;
  width: 440px;
  z-index: 4;
  animation: ${(props) => props.openSidePanel && 'openPanel 0.3s ease-in-out'};
  animation-delay: 0;

  @keyframes openPanel {
    0% {
      transform: translateX(440px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

export const SidePanel = ({mapState, openSidePanel, toggleDrawMapState}) => {
  return (
    <Container openSidePanel={openSidePanel}>
      {mapState == 'Info' && <InfoContent />}
      {mapState == 'Draw' && <DrawContent />}
      {mapState == 'Alert' && <AlertsContent toggleDrawMapState={toggleDrawMapState} />}
      {mapState == 'Message' && <CommentsContent />}
    </Container>
  );
};

SidePanel.propTypes = {
  mapState: PropTypes.string.isRequired,
  openSidePanel: PropTypes.bool.isRequired,
  toggleDrawMapState: PropTypes.func,
};
