import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {SectionTitle} from './common';

const HistoricChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.sideBar ? '100%' : 'calc(100% - 240px)')};
  padding: ${(props) => (props.sideBar ? '40px 24px 0 20px' : 'unset')};

  > img {
    padding: ${(props) => (props.sideBar ? '20px 0' : '40px')};
    padding-left: 0;
    width: 100%;
    height: auto;
  }
`;

export const HistoricOverView = ({sideBar}) => {
  return (
    <HistoricChartWrapper sideBar={sideBar}>
      <SectionTitle>Vegetation: Historic Overview</SectionTitle>
      <img src={sideBar ? './charts/Historic chart sidebar.png' : './charts/Historic Chart.png'} alt="chart" />
    </HistoricChartWrapper>
  );
};

HistoricOverView.propTypes = {
  sideBar: PropTypes.bool,
};
