import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose, faList} from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${(props) => (props.mapState !== 'Search' ? '56px' : '0')};
  right: 0;
  margin: 40px;
  border-radius: 8px;
  color: white;
  background: ${(props) => props.theme.colors.black};
  transition: 0.4s all;
  height: 244px;
  width: 362px;
  z-index: 4;
`;

const Description = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;
`;

const CloseButton = styled.button`
  color: white;
  font-size: 18px;
  padding: 12px;
  padding-right: 0;
  cursor: pointer;
  transition: 0.4s all;
  :hover {
    color: ${(props) => props.theme.colors.lemon};
  }
`;

const Header = styled.div`
  height: 80px;
  border-bottom: 1px solid #5d5c6c;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
`;

const Gradient = styled.div`
  height: 24px;
  width: 100%;
  background: linear-gradient(90deg, #d85b57 0%, #f9e096 50%, #00876c 100%);
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LeftColumn = styled.div`
  text-align: left;
  width: 30%;
`;

const RightColumn = styled(LeftColumn)`
  text-align: right;
`;

export const Legend = ({toggleLegend, mapState}) => {
  return (
    <Container mapState={mapState}>
      <Header>
        <Description>
          <FontAwesomeIcon icon={faList} />
          Legend
        </Description>
        <CloseButton onClick={toggleLegend}>
          <FontAwesomeIcon icon={faClose} />
        </CloseButton>
      </Header>
      <InfoWrapper>
        <Row>
          <LeftColumn>Vegetation Decrease</LeftColumn>
          <RightColumn>Vegetation Increase</RightColumn>
        </Row>
        <Gradient />
        <Row>
          <LeftColumn>-100%</LeftColumn>
          0%
          <RightColumn>100%</RightColumn>
        </Row>
      </InfoWrapper>
    </Container>
  );
};

Legend.propTypes = {
  toggleLegend: PropTypes.func.isRequired,
  mapState: PropTypes.string,
};
