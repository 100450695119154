import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Row} from './common';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faMap} from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 40px 24px;
  height: 320px;
  min-height: 320px;
  justify-content: space-between;
  background: linear-gradient(99.09deg, #16171c 24.4%, rgba(22, 23, 28, 0) 100%),
    url('${(props) => props.url}') center no-repeat;
  background-size: cover;

  > button {
    cursor: pointer;
    color: white;
    display: flex;
    gap: 16px;
    font-size: 16px;
    transition: 0.4s all;
    width: 205px;

    :hover {
      color: ${(props) => props.theme.colors.lemon};
    }
  }
`;

const Title = styled.h4`
  color: white;
  font-size: 48px;
  font-weight: 700;
  margin: 0;

  > span {
    color: ${(props) => props.theme.colors.lemon};
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  border-radius: 4px;
  padding: 14px 12px;
  height: 48px;
  color: ${(props) => props.theme.colors.black};
  background: ${(props) => props.theme.colors.lemon};
  margin-top: 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: 0.4s all;

  :hover {
    border: 1px solid ${(props) => props.theme.colors.lemon};
    background: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.lemon};
  }
`;

export const Header = ({area, closeStatistics, toggleDrawMapState}) => {
  return (
    <Wrapper url={area.url}>
      <button onClick={closeStatistics}>
        <FontAwesomeIcon icon={faChevronLeft} />
        Back to Dashboard
      </button>
      <Row>
        <Title>Overview: {area.title}</Title>
        <Button onClick={toggleDrawMapState}>
          <FontAwesomeIcon icon={faMap} />
          Explore on map
        </Button>
      </Row>
    </Wrapper>
  );
};

Header.propTypes = {
  area: PropTypes.object,
  closeStatistics: PropTypes.func,
  toggleDrawMapState: PropTypes.func,
};
