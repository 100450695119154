import React, {useState} from 'react';
import styled from 'styled-components';

import {MESSAGES} from './constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCog, faPlus} from '@fortawesome/free-solid-svg-icons';
import {ToolBar, ToolButton} from './common';
import {CommentsActions} from './comment/comment-actions';
import {Comment} from './comment/comment';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  height: 100%;
  width: 100%;
  gap: 24px;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 24px;
  font-weight: 500;
  padding: 24px 16px 24px 24px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #5d5c6c;
`;

export const CommentsContent = () => {
  const [manageComment, setManageComment] = useState(false);

  const toggleCommentActions = () => setManageComment(!manageComment);

  return (
    <Container>
      <Header>
        Comments
        <ToolBar>
          <ToolButton>
            <FontAwesomeIcon icon={faPlus} />
          </ToolButton>
          <ToolButton onClick={toggleCommentActions}>
            <FontAwesomeIcon icon={faCog} />
          </ToolButton>
        </ToolBar>
      </Header>
      {MESSAGES.map((item) => {
        return (
          <Comment
            key={item.id}
            color={item.color}
            message1={item.message1}
            message2={item.message2}
            name={item.name}
            initials={item.initials}
            lastSeen={item.lastSeen}
            user={item.user}
          />
        );
      })}
      {manageComment && <CommentsActions manageComment={manageComment} setManageComment={setManageComment} />}
    </Container>
  );
};
