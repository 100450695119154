export const AREAS = [
  {
    id: 0,
    url: './alerts/Alert Card 01.webp',
    title: 'Alpha–3 Monitoring',
    alerts: [
      {id: 0, index: +60, period: 'June, 2022 – July, 2022'},
      {id: 1, index: +20, period: 'May, 2022 – June, 2022'},
      {id: 2, index: -5, period: 'April, 2022 – May, 2022'},
    ],
    vegetation: -12,
    increase: +5,
    decrease: -48,
    danger: 'Very High',
  },
  {
    id: 1,
    url: './alerts/Alert Card 02.webp',
    title: 'Alpha–5 Monitoring',
    alerts: [
      {id: 0, index: +55, period: 'June, 2022 – July, 2022'},
      {id: 1, index: -16, period: 'May, 2022 – June, 2022'},
      {id: 2, index: +45, period: 'April, 2022 – May, 2022'},
      {id: 3, index: -15, period: 'May, 2022 – June, 2022'},
    ],
    vegetation: -6,
    increase: +5,
    decrease: -48,
    danger: 'Very High',
  },
  {
    id: 2,
    url: './alerts/Alert Card 03.webp',
    title: 'Alpha–1 Monitoring',
    vegetation: -6,
    increase: +25,
    decrease: -18,
    danger: 'High',
  },
  {
    id: 3,
    url: './alerts/Alert Card 04.webp',
    title: 'Alpha–2 Monitoring',
    vegetation: -14,
    increase: -15,
    decrease: -48,
    danger: 'Very High',
  },
  {
    id: 4,
    url: './alerts/Alert Card 05.webp',
    title: 'Alpha–4 Monitoring',
    vegetation: +18,
    increase: +25,
    decrease: +14,
    danger: 'Low',
  },
];
