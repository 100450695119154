import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell, faChevronRight} from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  flex-basis: calc(50% - 20px);
  background: url('${(props) => props.url}') center no-repeat;
  background-size: cover;
  height: 280px;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.4s all;

  :hover {
    transform: scale(1.02);
  }
`;

const Shadow = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(22, 23, 28, 0.7);
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: 0.4s all;
  position: relative;

  ${Container}:hover & {
    background: rgba(22, 23, 28, 0.55);
  }
`;

const Title = styled.h2`
  color: white;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
`;

const ViewButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.colors.lemon};
  cursor: pointer;
  gap: 11px;
  font-size: 16px;
  margin-top: 15px;

  > span {
    font-size: 18px;
  }
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-size: 16px;
`;

const Threshold = styled.span`
  color: #b2b2b2;
`;

const Vegetation = styled.span`
  color: white;
`;

const Alarm = styled.span`
  color: #ff6b76;
`;

const Notification = styled.div`
  background: ${(props) => props.theme.colors.lemon};
  width: 168px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 8px;
  gap: 12px;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.4s all;

  :hover {
    background: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.lemon};
    border: 1px solid ${(props) => props.theme.colors.lemon};
  }

  > span {
    font-size: 18px;
  }
`;

export const AreaCard = ({title, url, alerts, vegetation, onClick, openStatisticsOverview}) => {
  return (
    <Container url={url}>
      <Shadow>
        <Title>{title}</Title>
        <InfoRow>
          {vegetation < -13 ? (
            <Alarm>{vegetation}% Vegetation</Alarm>
          ) : (
            <Vegetation>{vegetation}% Vegetation</Vegetation>
          )}
          <Threshold>Threshold:-13%</Threshold>
        </InfoRow>
        <ViewButton onClick={openStatisticsOverview}>
          View statistics
          <span>
            <FontAwesomeIcon icon={faChevronRight} />
          </span>
        </ViewButton>
        {alerts && (
          <Notification onClick={onClick}>
            <span>
              <FontAwesomeIcon icon={faBell} />
            </span>
            {alerts} active alerts
          </Notification>
        )}
      </Shadow>
    </Container>
  );
};

AreaCard.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  alerts: PropTypes.number,
  vegetation: PropTypes.number,
  openStatisticsOverview: PropTypes.func,
};
