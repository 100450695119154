export const MEMBERS_LIST = [
  {
    id: 0,
    color: '#20e3c0',
    initials: 'JW',
    name: 'Julliette Watson',
    eMail: 'You',
  },
  {
    id: 1,
    color: '#b120e3',
    initials: 'MH',
    name: 'Maximillian Hatraw',
    eMail: 'mh@forestry.com',
  },
  {
    id: 2,
    color: '#e3c420',
    initials: 'AZ',
    name: 'Angela Ziegler',
    eMail: 'mh@forestry.com',
  },
  {
    id: 3,
    color: '#e320ad',
    initials: 'EV',
    name: 'Elizabeth de Vitte',
    eMail: 'mh@forestry.com',
  },
];

export const MESSAGES = [
  {
    id: 0,
    color: '#b120e3',
    initials: 'MH',
    name: 'Maximillian Hatraw',
    lastSeen: '10h ago',
    message1: 'I bet we should inform USFS that something very suspicious happens here. ',
    message2: ' please take care of that.',
    user: '@Julliette Watson',
  },
  {
    id: 1,
    color: '#20e3c0',
    initials: 'JW',
    name: 'Julliette Watson',
    lastSeen: '2d ago',
    message2:
      ' please send me 2005-2022 forest state report ASAP. It seems that the situation has significantly improved – so need to send this to USFS.',
    user: '@Angela Ziegler',
  },
];
