import styled from 'styled-components';

export const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: white;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
