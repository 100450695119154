import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {Header} from './common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  background: ${(props) => props.theme.colors.black};
  height: 100%;
  width: 100%;
  padding: 40px 32px;
  gap: 40px;
`;

const Chart = styled.div`
  > img {
    width: 100%;
    height: auto;
  }
`;

const Statisctics = styled.div`
  display: flex;
  flex-direction: row;
`;

const Change = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;

  :last-child {
    align-items: flex-end;
    text-align: right;
  }
`;

const Vegetation = styled.span`
  color: #2aba67;
  font-weight: 700;
  font-size: 48px;
`;

const Value = styled(Vegetation)`
  color: #b2b2b2;
`;

export const InfoContent = () => {
  return (
    <Container>
      <Header>Vegetation</Header>
      <Chart>
        <img src={'./charts/info-chart.png'} alt="chart" />
      </Chart>
      <Statisctics>
        <Change>
          <Vegetation>+9%</Vegetation>
          Vegetation change from April to May
        </Change>
        <Change>
          <Value>+3%</Value>
          Normal value for the same period
        </Change>
      </Statisctics>
    </Container>
  );
};

InfoContent.propTypes = {};
