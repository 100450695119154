import {FlyToInterpolator} from 'deck.gl';

const FOREST_LAT = 0.58243;
const FOREST_LONG = -50.56603;

export const INITIAL_VIEW_STATE = {
  id: 'SAN_FRANCISCO',
  longitude: FOREST_LONG,
  latitude: FOREST_LAT,
  zoom: 13.7,
  minZoom: 5,
  maxZoom: 18,
  pitch: 20,
  maxPitch: 60,
  bearing: 0,
  transitionDuration: 3000,
  transitionInterpolator: new FlyToInterpolator(),
};

export const MAPBOX_TOKEN =
  'pk.eyJ1IjoidmFsZXJpYWJvbG9uaWNoZXZhIiwiYSI6ImNsNHQwOHp3cjA4MTIzam16OHBnbDFwY3cifQ._S-dEeZWRBOauL4Pr6OpOQ';

export const DARK_MAP = 'DARK MAP';
export const SATELLITE = 'SATELLITE';
export const LIGHT_MAP = 'LIGHT MAP';

export const MAPS = [
  {
    id: 0,
    url: './layers/Heatmap.webp',
    name: LIGHT_MAP,
    title: 'Light map',
    map: 'https://basemaps.cartocdn.com/gl/voyager-nolabels-gl-style/style.json',
  },
  {
    id: 1,
    url: './layers/Default.webp',
    name: DARK_MAP,
    title: 'Dark map',
    map: 'https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json',
  },
  {
    id: 2,
    url: './layers/Satellite.webp',
    name: SATELLITE,
    title: 'Default',
    map: 'mapbox://styles/mapbox/satellite-v9',
  },
];

export const COLOR_RANGE = [
  [0, 135, 108],
  [123, 186, 109],
  [177, 215, 120],
  [221, 237, 151],
  [255, 254, 198],
  [249, 224, 150],
  [241, 176, 110],
  [227, 117, 79],
  [198, 64, 50],
];

export const MAIN_DATA = 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/sf-bike-parking.json';

export const MESSAGES_COORDINATES = [
  {
    id: 0,
    coordinates: [-122.392612, 37.616035],
  },
  {
    id: 1,
    coordinates: [-122.444116, 37.664174],
  },
  {
    id: 2,
    coordinates: [-122.419694, 37.765062],
  },
  {
    id: 3,
    coordinates: [-122.418466, 37.752254],
  },
];

export const PROMT_TEXT = 'Specify a point on the map for which to show detailed statistics';
