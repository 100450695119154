import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Onest";
    src: url("/fonts/OnestRegular.ttf");
    font-style: normal;
    font-weight: 400;
  }

  * {
    box-sizing: border-box;
  }

  #app {
    width: 100vw;
    height: 100vh;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  body {
    padding: 0;
    margin: 0;
    color: ${(props) => props.theme.colors.black};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Onest';
    width: 100%;
    height: 100%;
  }

  *:focus {
    outline: 1px solid #cccccc;
  }

  button:focus { 
    outline: none;
  }

  button {
    border: none;
    background: none;
  }

  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    margin-left: 16px;
    width: calc(100% - 100px);
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
  }

  .mapboxgl-ctrl-geocoder--input {
    width: 100%;
  }

  .mapboxgl-ctrl-logo {
    display: none !important;
  }
  
  .mapboxgl-ctrl-bottom-right {
    display: none !important;
  }
  
  .slick-prev:before, .slick-next:before {
    display: none !important;
  }

  .slick-slider {
    div[tabindex='-1'] {
      :focus {
        outline: none;
      }
    }
  }

  .slick-slide .slick-active .slick-current {
    outline: none !important;
    border: none;
  }

  .slick-slide {
    outline: none !important;
    border: none;

    :hover {
      outline: none !important;
      border: none;
    }
  }

  input {
    border: none;
    font-size: 16px;
    cursor: pointer;
  }

  textarea:focus, input:focus {
    outline: none;
  }

  ul.suggestions {
    background: #16171c;
    color: white;
    position: absolute;
    top: 30px;
    left: -16px;
    border-radius: 8px;
    padding: 16px;
    width: 328px;
    list-style-type: none;
  }

  .suggestions > li {
    cursor: pointer;
    font-size: 14px;
    padding: 8px 0;
  }

  .suggestions > li:hover {
    color: #ffec26;
  }

  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
    margin-left: 16px;
    width: calc(100% - 100px);
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
  }

  .mapboxgl-ctrl-geocoder--input {
    width: 100%;
    background: #16171c;
    color: white;
  }

  .mapboxgl-ctrl-geocoder--pin-right {
    margin-right: -32px;
    margin-bottom: -4px;
    cursor: pointer;
  }

  .mapboxgl-ctrl-geocoder--pin-right:hover {
    background: #16171c;
  }

  .mapboxgl-ctrl-geocoder--button {
    border: none;
    background: #16171c;
    cursor: pointer;
  }

  .mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-search {
    display: none;
  }

  .mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-loading {
    display: none;
  }

  .mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-close {
    fill: #b2b2b2;
  }

  .mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-close:hover {
    fill: #ffec26;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #0e111a;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.4);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #0e111a;
  }

`;

export {GlobalStyles};
