import React, {useState} from 'react';
import styled from 'styled-components';

import {Statistics} from '../statistics/statistics-overview';
import {AREAS} from '../dashboard/constants';
import {Button, InvertButton} from '../common';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell, faMessage} from '@fortawesome/free-solid-svg-icons';
import {ShareView} from './share-view';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #5d5c6c;
  padding: 24px 16px 10px 16px;
  gap: 16px;
`;

const ViewTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.02em;
  color: ${(props) => (props.active ? props.theme.colors.lemon : props.theme.colors.white)};
  display: flex;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  position: relative;

  ::after {
    content: '';
    border-bottom: 1px solid #ffec26;
    width: 100%;
    position: absolute;
    bottom: -11px;
    display: ${(props) => (props.active ? 'block' : 'none')};
  }
`;

const Actions = styled.div`
  position: absolute;
  bottom: 0;
  height: 96px;
  width: 100%;
  padding: 24px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(180deg, rgba(12, 13, 18, 0.6) 0%, #0c0d12 25.91%);
`;

const VIEW_STATE = [
  {
    id: 0,
    title: 'Area Overview',
  },
  {
    id: 1,
    title: 'Share view',
  },
];

export const DrawContent = () => {
  const [view, setView] = useState(VIEW_STATE[0].title);

  const toggleView = (view) => {
    setView(view);
  };

  return (
    <Container>
      <Header>
        {VIEW_STATE.map((item) => {
          return (
            <ViewTitle key={item.id} onClick={() => toggleView(item.title)} active={item.title == view}>
              {item.title}
            </ViewTitle>
          );
        })}
      </Header>
      {view == VIEW_STATE[0].title ? <Statistics area={AREAS[0]} sideBar /> : <ShareView />}
      {view == VIEW_STATE[0].title && (
        <Actions>
          <Button>
            <FontAwesomeIcon icon={faMessage} />
            Leave comment
          </Button>
          <InvertButton>
            <FontAwesomeIcon icon={faBell} />
            Create alert
          </InvertButton>
        </Actions>
      )}
    </Container>
  );
};
