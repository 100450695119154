import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import {MONTHS_LIST} from './constants';
import {NextButton, PrevButton} from './arrow-button';
import {Month} from './month';

const Wrapper = styled.div`
  position: absolute;
  bottom: 40px;
  transition: 0.4s all;
  right: ${(props) => (props.openSidePanel ? 'calc(50% - 50px)' : 'calc(50% - 180px)')};
  z-index: 7;
`;

const CustomSlider = styled(Slider)`
  width: 360px;
  height: 100%;
  padding: 16px 66px;
  border-radius: 16px;
  background: ${(props) => props.theme.colors.black};
`;

const sliderSettings = {
  dots: false,
  draggable: true,
  swipeToSlide: true,
  swipe: true,
  speed: 500,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  touchMove: true,
};

const DateSlider = ({openSidePanel}) => {
  const [activeMonth, setActiveMonth] = useState(MONTHS_LIST[3].id);

  return (
    <Wrapper openSidePanel={openSidePanel}>
      <CustomSlider
        {...sliderSettings}
        slidesToShow={3}
        slidesToScroll={1}
        afterChange={(current) => setActiveMonth(current)}
        initialSlide={3}
      >
        {MONTHS_LIST.map((item) => {
          if (item.id == 0) {
            return null;
          } else
            return (
              <Month key={item.id} title={item.month} active={item.id == activeMonth + 2} invisible={item.id == 1} />
            );
        })}
      </CustomSlider>
    </Wrapper>
  );
};

DateSlider.propTypes = {
  openSidePanel: PropTypes.bool,
};

export default DateSlider;
