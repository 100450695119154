import {IconLayer} from 'deck.gl';
import {MESSAGES_COORDINATES} from '../constants';

export const renderMessagesPinLayer = () => {
  return new IconLayer({
    id: 'Pin',
    data: MESSAGES_COORDINATES,
    billboard: true,
    getIcon: (d) => 'pin',
    getPosition: (d) => d.coordinates,
    getSize: () => 7,
    iconAtlas: 'message-pin.png',
    iconMapping: {
      pin: {
        x: 0,
        y: 0,
        width: 40,
        height: 48,
        anchorY: 50,
        mask: false,
      },
    },
    sizeScale: 7,
    alphaCutoff: 0.5,
  });
};
