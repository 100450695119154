import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faCog, faPlus, faSortAmountUp} from '@fortawesome/free-solid-svg-icons';
import {Alert} from '../alert';
import {AREAS} from '../dashboard/constants';
import {ToolBar, ToolButton} from './common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 24px;
  font-weight: 500;
  padding: 24px 16px 24px 24px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #5d5c6c;

  > span {
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  > span > label {
    font-size: 24px;
    cursor: pointer;
  }
`;

const AlertsList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const AlertsContent = ({toggleDrawMapState}) => {
  const [manageAlert, setManageAlert] = useState(false);

  const toggleManageAlert = () => {
    setManageAlert(!manageAlert);
  };

  return (
    <Container>
      <Header>
        {manageAlert ? (
          <span onClick={toggleManageAlert}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <label>Manage alerts</label>
          </span>
        ) : (
          'Alerts'
        )}
        <ToolBar>
          <ToolButton>
            <FontAwesomeIcon icon={faPlus} />
          </ToolButton>
          {!manageAlert && (
            <>
              <ToolButton>
                <FontAwesomeIcon icon={faSortAmountUp} />
              </ToolButton>
              <ToolButton onClick={toggleManageAlert}>
                <FontAwesomeIcon icon={faCog} />
              </ToolButton>
            </>
          )}
        </ToolBar>
      </Header>
      <AlertsList>
        <Alert
          title={AREAS[0].title}
          index={AREAS[0].alerts[0].index}
          period={AREAS[0].alerts[0].period}
          toggleDrawMapState={toggleDrawMapState}
          manageAlert={manageAlert}
        />
      </AlertsList>
    </Container>
  );
};

AlertsContent.propTypes = {
  toggleDrawMapState: PropTypes.func,
};
