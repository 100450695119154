import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {faEllipsis} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar, Member, MemberRow} from '../share-view';
import {CommentAction} from './comment-action';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  gap: 20px;
  border-radius: 4px;
  margin: 0 8px;
  position: relative;

  :hover {
    background: #1e1c19;
  }
`;

const CommentText = styled.div`
  > span {
    color: #26d8ff;
  }
`;

const CommentTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  > span {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      background: #1f2129;
    }
  }
`;

export const Comment = ({color, initials, name, lastSeen, message1, message2, user}) => {
  const [openActions, setOpenActions] = useState(false);

  const toggleActions = () => setOpenActions(!openActions);

  return (
    <Wrapper>
      <CommentTitle>
        <MemberRow>
          <Avatar color={color}>{initials}</Avatar>
          <Member>
            <span>{name}</span>
            <label>{lastSeen}</label>
          </Member>
        </MemberRow>
        <span onClick={toggleActions}>
          <FontAwesomeIcon icon={faEllipsis} />
        </span>
      </CommentTitle>
      <CommentText>
        {message1}
        <span>{user}</span>
        {message2}
      </CommentText>
      {openActions && <CommentAction openActions={openActions} setOpenActions={setOpenActions} />}
    </Wrapper>
  );
};

Comment.propTypes = {
  color: PropTypes.string,
  initials: PropTypes.string,
  name: PropTypes.string,
  lastSeen: PropTypes.string,
  message1: PropTypes.string,
  message2: PropTypes.string,
  user: PropTypes.string,
};
