import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faClose, faList} from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  margin: 40px;
  gap: 8px;
  z-index: 3;
`;

// TODO crete 1 element for all buttons in the app
const Button = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  font-size: 18px;
  border-radius: 8px;
  color: white;
  background: ${(props) => props.theme.colors.black};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s all;

  :hover {
    background: ${(props) => props.theme.colors.lemon};
    color: ${(props) => props.theme.colors.black};
  }
`;

const PanelButton = styled(Button)`
  margin-right: ${(props) => (props.openSidePanel ? '408px' : '0')};
`;

export const InfoToolsBar = ({toggleLegend, mapState, toggleSidePanel, openSidePanel}) => {
  return (
    <Wrapper>
      {mapState !== 'Search' && (
        <PanelButton onClick={toggleSidePanel} openSidePanel={openSidePanel}>
          {openSidePanel ? <FontAwesomeIcon icon={faClose} /> : <FontAwesomeIcon icon={faChevronLeft} />}
        </PanelButton>
      )}
      {!openSidePanel && (
        <Button onClick={toggleLegend}>
          <FontAwesomeIcon icon={faList} />
        </Button>
      )}
    </Wrapper>
  );
};

InfoToolsBar.propTypes = {
  toggleLegend: PropTypes.func.isRequired,
  toggleSidePanel: PropTypes.func.isRequired,
  mapState: PropTypes.string,
  openSidePanel: PropTypes.bool,
};
