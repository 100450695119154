import styled from 'styled-components';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 14px 15px;
  border: 1px solid ${(props) => props.theme.colors.lemon};
  color: ${(props) => props.theme.colors.lemon};
  margin-top: 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: 0.4s all;
  gap: 12px;

  :hover {
    color: ${(props) => props.theme.colors.black};
    background: ${(props) => props.theme.colors.lemon};
  }
`;

export const InvertButton = styled(Button)`
  color: ${(props) => props.theme.colors.black};
  background: ${(props) => props.theme.colors.lemon};
  border: none;

  :hover {
    border: 1px solid ${(props) => props.theme.colors.lemon};
    background: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.lemon};
  }
`;
